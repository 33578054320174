import { storableError } from '../../util/errors';
import { denormalisedResponseEntities } from '../../util/data';
import { currentUserShowSuccess } from '../../ducks/user.duck';

import { fetchCurrentUser } from '../../ducks/user.duck';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';

// ================ Action types ================ //

export const FETCH_USERS_REQUEST = 'app/FavouriteListingsPage/FETCH_USERS_REQUEST';
export const FETCH_USERS_SUCCESS = 'app/FavouriteListingsPage/FETCH_USERS_SUCCESS';
export const FETCH_USERS_ERROR = 'app/FavouriteListingsPage/FETCH_USERS_ERROR';

export const UPDATE_CURRENTUSER_REQUEST = 'app/SearchPage/UPDATE_CURRENTUSER_REQUEST';
export const UPDATE_CURRENTUSER_SUCCESS = 'app/SearchPage/UPDATE_CURRENTUSER_SUCCESS';
export const UPDATE_CURRENTUSER_ERROR = 'app/SearchPage/UPDATE_CURRENTUSER_ERROR';

// ================ Reducer ================ //

const initialState = {
  users: [],
  updateCurrentUserInProgress: false,
  updateCurrentUserError: null,
};

const FavouriteListingsPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {

    case FETCH_USERS_REQUEST:
      return {
        ...state,
        users: [],
      }
    case FETCH_USERS_SUCCESS:
      return {
        ...state,
        users: payload,
      }
    case FETCH_USERS_ERROR:
      return {
        ...state,
        users: [],
      }

    case UPDATE_CURRENTUSER_REQUEST:
      return { ...state, updateCurrentUserInProgress: true, updateCurrentUserError: null };
    case UPDATE_CURRENTUSER_SUCCESS:
      return { ...state, updateCurrentUserInProgress: false };
    case UPDATE_CURRENTUSER_ERROR:
      return { ...state, updateCurrentUserInProgress: false, updateCurrentUserError: payload };

    default:
      return state;
  }
};

export default FavouriteListingsPageReducer;

// ================ Selectors ================ //

// ================ Action creators ================ //

export const fetchUsersRequest = () => ({
  type: FETCH_USERS_REQUEST,
});
export const fetchUsersSuccess = response => ({
  type: FETCH_USERS_SUCCESS,
  payload: response.data,
});
export const fetchUsersError = e => ({
  type: FETCH_USERS_ERROR,
  error: true,
  payload: e,
});


// SDK method: sdk.currentUser.updateProfile
export const updateCurrentUserRequest = params => ({
  type: UPDATE_CURRENTUSER_REQUEST,
  payload: { params },
});
export const updateCurrentUserSuccess = result => ({
  type: UPDATE_CURRENTUSER_SUCCESS,
  payload: result.data,
});
export const updateCurrentUserError = error => ({
  type: UPDATE_CURRENTUSER_ERROR,
  payload: error,
  error: true,
});

export const fetchUser = (userIds, config) => (dispatch, getState, sdk) => {
  const userFields = config?.user?.userFields;
  const sanitizeConfig = { userFields };

  return Promise.all(
    userIds.map(userId =>
      sdk.users
        .show({
          id: userId,
          include: ['profileImage'],
          'fields.image': ['variants.square-small', 'variants.square-small2x'],
          'fields.profileImage': ['variants.square-small', 'variants.square-small2x'],
        })
    )
  )
    .then(responses => {
      responses.forEach(response => {
        dispatch(addMarketplaceEntities(response, sanitizeConfig));
      });
      dispatch(fetchUsersSuccess({ data: responses.map(response => response.data) }));
      return responses;
    })
    .catch(e => {
      dispatch(fetchUsersError(storableError(e)));
    });
};

// Update the current user with the given data.
export const updateCurrentUser = values => (dispatch, getState, sdk) => {
  dispatch(updateCurrentUserRequest());

  return sdk.currentUser
    .updateProfile(values,
      {
        expand: true,
        include: ['profileImage'],
        'fields.image': ['variants.square-small', 'variants.square-small2x'],
      })
    .then(response => {
      dispatch(updateCurrentUserSuccess(response));

      const entities = denormalisedResponseEntities(response);
      if (entities.length !== 1) {
        throw new Error('Expected a resource in the sdk.currentUser.updateProfile response');
      }

      const currentUser = entities[0];

      dispatch(currentUserShowSuccess(currentUser));

      // Update users array to remove the deleted user from the favourite list
      const updatedUserIds = currentUser.attributes.profile.publicData.wishlists;

      const userIds = updatedUserIds.reduce((acc, category) => {
        return acc.concat(category.users);
      }, []);
      const uniqueUserIds = [...new Set(userIds)];

      console.log("updatedUserIds", uniqueUserIds);
      dispatch(fetchUser(uniqueUserIds));
    })
    .catch(e => {
      dispatch(updateCurrentUserError(storableError(e)));
      throw e;
    });
};

export const loadData = (params, search, config) => (dispatch, getState, sdk) => {

  return Promise.all([
    dispatch(fetchCurrentUser()),
  ])
    .then(response => {
      const currentUser = getState().user.currentUser;
      const wishlist = currentUser ? currentUser.attributes.profile.publicData.wishlists : [];

      // Extract user IDs from the nested structure
      const userIds = wishlist.reduce((acc, category) => {
        return acc.concat(category.users);
      }, []);
      const uniqueUserIds = [...new Set(userIds)];
      // Fetch users
      return dispatch(fetchUser(uniqueUserIds, config));
    })
    .catch(e => {
      throw e;
    });
};
