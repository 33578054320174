import React from 'react';
import classNames from 'classnames';

import css from './IconVerified.module.css';

/**
 * Success icon.
 *
 * @component
 * @param {Object} props
 * @param {string?} props.className add more style rules in addition to components own css.root
 * @param {string?} props.rootClassName overwrite components own css.root
 * @param {string?} props.fillColor overwrite components own css.fillColor
 * @returns {JSX.Element} SVG icon
 */
const IconVerified = props => {
    const { rootClassName, className, fillColor } = props;
    const classes = classNames(rootClassName || css.root, className);
    return (
        <svg width="36" height="36" viewBox="0 0 36 36" fill="none" className={classes} xmlns="http://www.w3.org/2000/svg">
            <path d="M16.425 20.145L13.731 17.46C13.591 17.32 13.4205 17.245 13.2195 17.235C13.0175 17.225 12.834 17.303 12.669 17.469C12.509 17.629 12.429 17.806 12.429 18C12.429 18.194 12.509 18.371 12.669 18.531L15.576 21.438C15.819 21.68 16.102 21.801 16.425 21.801C16.748 21.801 17.031 21.68 17.274 21.438L23.331 15.381C23.477 15.235 23.5535 15.063 23.5605 14.865C23.5675 14.666 23.491 14.484 23.331 14.319C23.166 14.154 22.9875 14.07 22.7955 14.067C22.6035 14.064 22.4255 14.145 22.2615 14.31L16.425 20.145ZM13.005 30.924L11.031 27.624L7.30949 26.838C7.01149 26.783 6.77399 26.623 6.59699 26.358C6.41999 26.094 6.34699 25.813 6.37799 25.515L6.73349 21.681L4.20899 18.801C3.99699 18.584 3.89099 18.317 3.89099 18C3.89099 17.683 3.99699 17.416 4.20899 17.199L6.73349 14.319L6.37799 10.4865C6.34799 10.1875 6.42099 9.90601 6.59699 9.64201C6.77399 9.37801 7.01149 9.21801 7.30949 9.16201L11.0295 8.37751L13.0035 5.07751C13.1655 4.80951 13.383 4.62451 13.656 4.52251C13.929 4.41951 14.2115 4.43301 14.5035 4.56301L18 6.04051L21.495 4.56301C21.788 4.43301 22.071 4.41951 22.344 4.52251C22.617 4.62451 22.8345 4.80951 22.9965 5.07751L24.969 8.37751L28.6905 9.16201C28.9885 9.21801 29.226 9.37801 29.403 9.64201C29.58 9.90601 29.653 10.1875 29.622 10.4865L29.268 14.319L31.791 17.199C32.003 17.416 32.109 17.683 32.109 18C32.109 18.317 32.003 18.5845 31.791 18.8025L29.268 21.681L29.622 25.5135C29.652 25.8125 29.579 26.094 29.403 26.358C29.226 26.623 28.9885 26.783 28.6905 26.838L24.9705 27.624L22.9965 30.924C22.8345 31.191 22.617 31.376 22.344 31.479C22.071 31.582 21.7885 31.568 21.4965 31.437L18 29.9595L14.505 31.437C14.212 31.567 13.929 31.5805 13.656 31.4775C13.383 31.3755 13.1655 31.1905 13.0035 30.9225" />
        </svg>
    );
};

export default IconVerified;
