import { isErrorUserPendingApproval, isForbiddenError, storableError } from '../../util/errors';
import { convertUnitToSubUnit, unitDivisor } from '../../util/currency';
import { denormalisedResponseEntities } from '../../util/data';
import { currentUserShowSuccess } from '../../ducks/user.duck';
import {
  parseDateFromISO8601,
  getExclusiveEndDate,
  addTime,
  subtractTime,
  daysBetween,
  getStartOf,
} from '../../util/dates';
import { constructQueryParamName, isOriginInUse } from '../../util/search';
import { hasPermissionToViewData, isUserAuthorized } from '../../util/userHelpers';
import { parse } from '../../util/urlHelpers';

import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';

import { queryUsers } from '../../util/api';

// Pagination page size might need to be dynamic on responsive page layouts
// Current design has max 3 columns 12 is divisible by 2 and 3
// So, there's enough cards to fill all columns on full pagination pages
const RESULT_PAGE_SIZE = 24;

// ================ Action types ================ //

export const SEARCH_LISTINGS_REQUEST = 'app/SearchPage/SEARCH_LISTINGS_REQUEST';
export const SEARCH_LISTINGS_SUCCESS = 'app/SearchPage/SEARCH_LISTINGS_SUCCESS';
export const SEARCH_LISTINGS_ERROR = 'app/SearchPage/SEARCH_LISTINGS_ERROR';

export const SEARCH_MAP_LISTINGS_REQUEST = 'app/SearchPage/SEARCH_MAP_LISTINGS_REQUEST';
export const SEARCH_MAP_LISTINGS_SUCCESS = 'app/SearchPage/SEARCH_MAP_LISTINGS_SUCCESS';
export const SEARCH_MAP_LISTINGS_ERROR = 'app/SearchPage/SEARCH_MAP_LISTINGS_ERROR';

export const SEARCH_MAP_SET_ACTIVE_LISTING = 'app/SearchPage/SEARCH_MAP_SET_ACTIVE_LISTING';

export const SEARCH_USERS_REQUEST = 'app/SearchPage/SEARCH_USERS_REQUEST';
export const SEARCH_USERS_SUCCESS = 'app/SearchPage/SEARCH_USERS_SUCCESS';
export const SEARCH_USERS_ERROR = 'app/SearchPage/SEARCH_USERS_ERROR';

export const UPDATE_CURRENTUSER_REQUEST = 'app/SearchPage/UPDATE_CURRENTUSER_REQUEST';
export const UPDATE_CURRENTUSER_SUCCESS = 'app/SearchPage/UPDATE_CURRENTUSER_SUCCESS';
export const UPDATE_CURRENTUSER_ERROR = 'app/SearchPage/UPDATE_CURRENTUSER_ERROR';

// ================ Reducer ================ //

const initialState = {
  pagination: null,
  searchParams: null,
  searchInProgress: false,
  searchListingsError: null,
  currentPageResultIds: [],
  searchUsersError: null,
  currentPageResultUserIds: [],
  updateCurrentUserInProgress: false,
  updateCurrentUserError: null,
};

const resultIds = data => {
  const listings = data.data;
  return listings
    .filter(l => !l.attributes.deleted && l.attributes.state === 'published')
    .map(l => l.id);
};

const usersResultIds = data => {
  const users = data.data;
  return users.map(u => u.id);
}

const listingPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case SEARCH_LISTINGS_REQUEST:
      return {
        ...state,
        searchParams: payload.searchParams,
        searchInProgress: true,
        searchMapListingIds: [],
        searchListingsError: null,
      };
    case SEARCH_LISTINGS_SUCCESS:
      return {
        ...state,
        currentPageResultIds: resultIds(payload.data),
        pagination: payload.data.meta,
        searchInProgress: false,
      };
    case SEARCH_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, searchInProgress: false, searchListingsError: payload };

    case SEARCH_MAP_SET_ACTIVE_LISTING:
      return {
        ...state,
        activeListingId: payload,
      };

    case SEARCH_USERS_REQUEST:
      return {
        ...state,
        searchInProgress: true,
        searchUsersError: null,
      };
    case SEARCH_USERS_SUCCESS:
      return {
        ...state,
        currentPageResultUserIds: usersResultIds(payload.data),
        pagination: payload.data.meta,
        searchInProgress: false,
      };
    case SEARCH_USERS_ERROR:
      // eslint-disable-next-line no-console
      console.log(payload);
      return { ...state, searchInProgress: false, searchUsersError: payload };

    case UPDATE_CURRENTUSER_REQUEST:
      return { ...state, updateCurrentUserInProgress: true, updateCurrentUserError: null };
    case UPDATE_CURRENTUSER_SUCCESS:
      return { ...state, updateCurrentUserInProgress: false };
    case UPDATE_CURRENTUSER_ERROR:
      return { ...state, updateCurrentUserInProgress: false, updateCurrentUserError: payload };

    default:
      return state;
  }
};

export default listingPageReducer;

// ================ Action creators ================ //

export const searchListingsRequest = searchParams => ({
  type: SEARCH_LISTINGS_REQUEST,
  payload: { searchParams },
});

export const searchListingsSuccess = response => ({
  type: SEARCH_LISTINGS_SUCCESS,
  payload: { data: response.data },
});

export const searchListingsError = e => ({
  type: SEARCH_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const searchUsersRequest = searchParams => ({
  type: SEARCH_USERS_REQUEST,
  payload: { searchParams },
});

export const searchUsersSuccess = response => ({
  type: SEARCH_USERS_SUCCESS,
  payload: { data: response.data },
});

export const searchUsersError = e => ({
  type: SEARCH_USERS_ERROR,
  error: true,
  payload: e,
});

// SDK method: sdk.currentUser.updateProfile
export const updateCurrentUserRequest = params => ({
  type: UPDATE_CURRENTUSER_REQUEST,
  payload: { params },
});
export const updateCurrentUserSuccess = result => ({
  type: UPDATE_CURRENTUSER_SUCCESS,
  payload: result.data,
});
export const updateCurrentUserError = error => ({
  type: UPDATE_CURRENTUSER_ERROR,
  payload: error,
  error: true,
});

export const searchListings = (searchParams, config) => (dispatch, getState, sdk) => {
  dispatch(searchListingsRequest(searchParams));

  // SearchPage can enforce listing query to only those listings with valid listingType
  // NOTE: this only works if you have set 'enum' type search schema to listing's public data fields
  //       - listingType
  //       Same setup could be expanded to 2 other extended data fields:
  //       - transactionProcessAlias
  //       - unitType
  //       ...and then turned enforceValidListingType config to true in configListing.js
  // Read More:
  // https://www.sharetribe.com/docs/how-to/manage-search-schemas-with-flex-cli/#adding-listing-search-schemas
  const searchValidListingTypes = listingTypes => {
    return config.listing.enforceValidListingType
      ? {
        pub_listingType: listingTypes.map(l => l.listingType),
        // pub_transactionProcessAlias: listingTypes.map(l => l.transactionType.alias),
        // pub_unitType: listingTypes.map(l => l.transactionType.unitType),
      }
      : {};
  };

  const omitInvalidCategoryParams = params => {
    const categoryConfig = config.search.defaultFilters?.find(f => f.schemaType === 'category');
    const categories = config.categoryConfiguration.categories;
    const { key: prefix, scope } = categoryConfig || {};
    const categoryParamPrefix = constructQueryParamName(prefix, scope);

    const validURLParamForCategoryData = (prefix, categories, level, params) => {
      const levelKey = `${categoryParamPrefix}${level}`;
      const levelValue = params?.[levelKey];
      const foundCategory = categories.find(cat => cat.id === levelValue);
      const subcategories = foundCategory?.subcategories || [];
      return foundCategory && subcategories.length > 0
        ? {
          [levelKey]: levelValue,
          ...validURLParamForCategoryData(prefix, subcategories, level + 1, params),
        }
        : foundCategory
          ? { [levelKey]: levelValue }
          : {};
    };

    const categoryKeys = validURLParamForCategoryData(prefix, categories, 1, params);
    const nonCategoryKeys = Object.entries(params).reduce(
      (picked, [k, v]) => (k.startsWith(categoryParamPrefix) ? picked : { ...picked, [k]: v }),
      {}
    );

    return { ...nonCategoryKeys, ...categoryKeys };
  };

  const priceSearchParams = priceParam => {
    const inSubunits = value => convertUnitToSubUnit(value, unitDivisor(config.currency));
    const values = priceParam ? priceParam.split(',') : [];
    return priceParam && values.length === 2
      ? {
        price: [inSubunits(values[0]), inSubunits(values[1]) + 1].join(','),
      }
      : {};
  };

  const datesSearchParams = datesParam => {
    const searchTZ = 'Etc/UTC';
    const datesFilter = config.search.defaultFilters.find(f => f.key === 'dates');
    const values = datesParam ? datesParam.split(',') : [];
    const hasValues = datesFilter && datesParam && values.length === 2;
    const { dateRangeMode, availability } = datesFilter || {};
    const isNightlyMode = dateRangeMode === 'night';
    const isEntireRangeAvailable = availability === 'time-full';

    // SearchPage need to use a single time zone but listings can have different time zones
    // We need to expand/prolong the time window (start & end) to cover other time zones too.
    //
    // NOTE: you might want to consider changing UI so that
    //   1) location is always asked first before date range
    //   2) use some 3rd party service to convert location to time zone (IANA tz name)
    //   3) Make exact dates filtering against that specific time zone
    //   This setup would be better for dates filter,
    //   but it enforces a UX where location is always asked first and therefore configurability
    const getProlongedStart = date => subtractTime(date, 14, 'hours', searchTZ);
    const getProlongedEnd = date => addTime(date, 12, 'hours', searchTZ);

    const startDate = hasValues ? parseDateFromISO8601(values[0], searchTZ) : null;
    const endRaw = hasValues ? parseDateFromISO8601(values[1], searchTZ) : null;
    const endDate =
      hasValues && isNightlyMode
        ? endRaw
        : hasValues
          ? getExclusiveEndDate(endRaw, searchTZ)
          : null;

    const today = getStartOf(new Date(), 'day', searchTZ);
    const possibleStartDate = subtractTime(today, 14, 'hours', searchTZ);
    const hasValidDates =
      hasValues &&
      startDate.getTime() >= possibleStartDate.getTime() &&
      startDate.getTime() <= endDate.getTime();

    const dayCount = isEntireRangeAvailable ? daysBetween(startDate, endDate) : 1;
    const day = 1440;
    const hour = 60;
    // When entire range is required to be available, we count minutes of included date range,
    // but there's a need to subtract one hour due to possibility of daylight saving time.
    // If partial range is needed, then we just make sure that the shortest time unit supported
    // is available within the range.
    // You might want to customize this to match with your time units (e.g. day: 1440 - 60)
    const minDuration = isEntireRangeAvailable ? dayCount * day - hour : hour;
    return hasValidDates
      ? {
        start: getProlongedStart(startDate),
        end: getProlongedEnd(endDate),
        // Availability can be time-full or time-partial.
        // However, due to prolonged time window, we need to use time-partial.
        availability: 'time-partial',
        // minDuration uses minutes
        minDuration,
      }
      : {};
  };

  const stockFilters = datesMaybe => {
    const hasDatesFilterInUse = Object.keys(datesMaybe).length > 0;

    // If dates filter is not in use,
    //   1) Add minStock filter with default value (1)
    //   2) Add relaxed stockMode: "match-undefined"
    // The latter is used to filter out all the listings that explicitly are out of stock,
    // but keeps bookable and inquiry listings.
    return hasDatesFilterInUse ? {} : { minStock: 1, stockMode: 'match-undefined' };
  };

  const seatsSearchParams = (seats, datesMaybe) => {
    const seatsFilter = config.search.defaultFilters.find(f => f.key === 'seats');
    const hasDatesFilterInUse = Object.keys(datesMaybe).length > 0;

    // Seats filter cannot be applied without dates
    return hasDatesFilterInUse && seatsFilter ? { seats } : {};
  };

  const { perPage, price, dates, seats, sort, mapSearch, ...restOfParams } = searchParams;
  const priceMaybe = priceSearchParams(price);
  const datesMaybe = datesSearchParams(dates);
  const stockMaybe = stockFilters(datesMaybe);
  const seatsMaybe = seatsSearchParams(seats, datesMaybe);
  const sortMaybe = sort === config.search.sortConfig.relevanceKey ? {} : { sort };

  const params = {
    // The rest of the params except invalid nested category-related params
    // Note: invalid independent search params are still passed through
    ...omitInvalidCategoryParams(restOfParams),
    ...priceMaybe,
    ...datesMaybe,
    ...stockMaybe,
    ...seatsMaybe,
    ...sortMaybe,
    ...searchValidListingTypes(config.listing.listingTypes),
    perPage,
  };

  return sdk.listings
    .query(params)
    .then(response => {
      const listingFields = config?.listing?.listingFields;
      const sanitizeConfig = { listingFields };

      dispatch(addMarketplaceEntities(response, sanitizeConfig));
      dispatch(searchListingsSuccess(response));
      return response;
    })
    .catch(e => {
      const error = storableError(e);
      dispatch(searchListingsError(error));
      if (!(isErrorUserPendingApproval(error) || isForbiddenError(error))) {
        throw e;
      }
    });
};

export const setActiveListing = listingId => ({
  type: SEARCH_MAP_SET_ACTIVE_LISTING,
  payload: listingId,
});

// Update the current user with the given data.
export const updateCurrentUser = values => (dispatch, getState, sdk) => {
  dispatch(updateCurrentUserRequest());

  return sdk.currentUser
    .updateProfile(values,
      {
        expand: true,
        include: ['profileImage'],
        'fields.image': ['variants.square-small', 'variants.square-small2x'],
      })
    .then(response => {
      dispatch(updateCurrentUserSuccess(response));

      const entities = denormalisedResponseEntities(response);
      if (entities.length !== 1) {
        throw new Error('Expected a resource in the sdk.currentUser.updateProfile response');
      }

      const currentUser = entities[0];

      dispatch(currentUserShowSuccess(currentUser));
    })
    .catch(e => {
      dispatch(updateCurrentUserError(storableError(e)));
      throw e;
    });
};

export const usersRequest = (searchParams, config) => (dispatch, getState, sdk) => {
  dispatch(searchUsersRequest(searchParams));
  const { perPage, pub_categoryLevel1, pub_categoryLevel2, pub_categoryLevel3, ...restOfParams } = searchParams;

  const pub_categoryLevel1Maybe = pub_categoryLevel1 ? { pub_categories: `has_all:${pub_categoryLevel1}` } : {};
  const pub_categoryLevel2Maybe = pub_categoryLevel2 ? { pub_categoriesLevel2: `has_all:${pub_categoryLevel2}` } : {};
  const pub_categoryLevel3Maybe = pub_categoryLevel3 ? { pub_categoriesLevel3: `has_all:${pub_categoryLevel3}` } : {};

  const params = {
    ...restOfParams,
    pub_hasListings: true,
    state: 'active',
    perPage,
    ...pub_categoryLevel1Maybe,
    ...pub_categoryLevel2Maybe,
    ...pub_categoryLevel3Maybe,
  };
  
  return queryUsers({ params: params })
    .then(response => {
      const userFields = config?.user?.userFields;
      const sanitizeConfig = { userFields };

      dispatch(addMarketplaceEntities(response, sanitizeConfig));
      dispatch(searchUsersSuccess(response));
      return response;
    })
    .catch(e => {
      const error = storableError(e);
      dispatch(searchUsersError(error));
      if (!(isErrorUserPendingApproval(error) || isForbiddenError(error))) {
        throw e;
      }
    });
}

export const loadData = (params, search, config) => (dispatch, getState, sdk) => {
  // In private marketplace mode, this page won't fetch data if the user is unauthorized
  const state = getState();
  const currentUser = state.user?.currentUser;
  const isAuthorized = currentUser && isUserAuthorized(currentUser);
  const hasViewingRights = currentUser && hasPermissionToViewData(currentUser);
  const isPrivateMarketplace = config.accessControl.marketplace.private === true;
  const canFetchData =
    !isPrivateMarketplace || (isPrivateMarketplace && isAuthorized && hasViewingRights);
  if (!canFetchData) {
    return Promise.resolve();
  }

  const queryParams = parse(search, {
    latlng: ['origin'],
    latlngBounds: ['bounds'],
  });
  const { page = 1, address, origin, bounds, pub_listingType, ...rest } = queryParams;
  
  const hasAddress = !!address;
  const splitAddress = hasAddress ? address.replace(" ", "").trim().split(',') : [];
  
  const combinedKeywords = queryParams.keywords ? queryParams.keywords.split(',').concat(splitAddress).join(',').toLowerCase() : splitAddress.join(',').toLowerCase();
  const keywordsMaybe = combinedKeywords ? { pub_keywords: 'has_any:' + combinedKeywords } : {};

  const pub_listingTypeMaybe = pub_listingType ? { pub_listingTypes: pub_listingType === 'marketer_requirement' ? 'has_any:marketer_requirement' : 'has_any:collab_availability' } : {};

  const searchUsersCall = usersRequest({
    ...rest,
    ...pub_listingTypeMaybe,
    ...keywordsMaybe,
    page,
    perPage: RESULT_PAGE_SIZE,
  }, config);

  // return Promise.all([searchListingsCall, searchUsersCall]);
  return dispatch(searchUsersCall);
};
